













































































































































































































import { useBlob } from "@/hooks";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { salesOrderServices } from "@/services/salesorder.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TrackingIc extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  page = 1 as number;
  limit = 10 as number;
  totalElements = 0 as number;
  dataSource = [] as any[];
  dataListCustomer = [] as ContactData[];
  dataListIcNumber = [] as any[];
  dataListEquipment = [] as any[];
  dataListTracking = [] as any[];
  loading = {
    find: false as boolean,
    customerName: false as boolean,
    icNumber: false as boolean,
    equipment: false as boolean,
    tracking: false as boolean,
    download: false as boolean,
  };
  downloadParams = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    customerName: "ALL",
    icNumber: "ALL",
    equipment: "ALL",
    tracking: "ALL",
    icDateFrom: "ALL",
    icDateTo: "ALL",
    search: "",
    unitCode: "ALL",
  };
  columnsTable = [
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
      key: "isNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_ic_date"),
      dataIndex: "icDate",
      key: "icDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      key: "equipment",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_price_unit_ic"),
      dataIndex: "price",
      key: "priceUnitIc",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_so_number"),
      dataIndex: "soNumber",
      key: "soNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_so_date"),
      dataIndex: "soDate",
      key: "soDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_do_number"),
      dataIndex: "doNumber",
      key: "doNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_do_date"),
      dataIndex: "doDate",
      key: "doDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_bast_number"),
      dataIndex: "bastNumber",
      key: "bastNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_bast_date"),
      dataIndex: "bastDate",
      key: "bastDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_bast_posting_date"),
      dataIndex: "bastPostDate",
      key: "bastPostingDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
  ] as any;
  formRules = {
    unitCode: {
      label: "lbl_unit_code",
      name: "unitCode",
      placeholder: "lbl_unit_code",
      decorator: ["unitCode"],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "lbl_customer_name",
      placeholder: "lbl_customer_name_placeholder",
      decorator: ["customerName"],
    },
    icNumber: {
      label: "lbl_ic_number",
      name: "lbl_ic_number",
      placeholder: "lbl_ic_number_placeholder",
      decorator: ["icNumber"],
    },
    equipment: {
      label: "lbl_equipment",
      name: "lbl_equipment",
      placeholder: "lbl_equipment_placeholder",
      decorator: ["equipment"],
    },
    tracking: {
      label: "lbl_tracking",
      name: "lbl_tracking",
      placeholder: "lbl_tracking_placeholder",
      decorator: ["tracking"],
    },
    icDateFrom: {
      label: "lbl_ic_date_from",
      name: "lbl_ic_date_from",
      placeholder: "lbl_ic_date_from_placeholder",
      decorator: ["icDateFrom"],
    },
    icDateTo: {
      label: "lbl_ic_date_to",
      name: "lbl_ic_date_to",
      placeholder: "lbl_ic_date_to_placeholder",
      decorator: ["icDateTo"],
    },
  };
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "trackingIc" });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  download() {
    const { toDownload } = useBlob();
    const params = {
      params: `${this.downloadParams.companyName},${this.downloadParams.customerName},${this.downloadParams.icNumber},${this.downloadParams.equipment},${this.downloadParams.unitCode},${this.downloadParams.tracking},${this.downloadParams.icDateFrom},${this.downloadParams.icDateTo}`,
    } as RequestQueryParamsModel;
    if (this.downloadParams.search) params.search = this.downloadParams.search;
    this.loading.download = true;
    salesOrderServices
      .downloadTrackingIc(params)
      .then(dataBlob => {
        toDownload(dataBlob, "tracking_ic_report.xlsx");
      })
      .finally(() => (this.loading.download = false));
  }

  clear() {
    this.form.resetFields();
  }
  getCustomer(value: string): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `customer~true_AND_active~true`,
    };
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.customerName = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loading.customerName = false));
  }
  mounted() {
    this.getCustomer("");
    this.getTracking();
    this.getEquipment("");
    this.getIcNumber("");
  }
  getIcNumber(value) {
    const params = {
      page: 0,
      limit: 10,
      icNumber: value,
    } as RequestQueryParamsModel;
    this.loading.icNumber = true;
    return salesOrderServices
      .listDropdownTrackingIc(params)
      .then(res => {
        this.dataListIcNumber = res;
      })
      .finally(() => {
        this.loading.icNumber = false;
      });
  }
  getEquipment(value) {
    const params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    if (value) params.search = `name~*${value}*`;
    this.loading.equipment = true;
    productService
      .listProductCategory(params)
      .then((res: any) => {
        this.dataListEquipment = res.data;
      })
      .finally(() => (this.loading.equipment = false));
  }
  getTracking() {
    const params = {
      name: "IC_TRACKING",
    } as RequestQueryParamsModel;
    this.loading.tracking = true;
    return masterServices
      .listMaster(params)
      .then(res => {
        this.dataListTracking = res;
      })
      .finally(() => (this.loading.tracking = false));
  }
  searchData(value: string, type: "customerName" | "icNumber" | "equipment") {
    switch (type) {
      case "customerName":
        this.getCustomer(value);
        break;
      case "icNumber":
        this.getIcNumber(value);
        break;
      case "equipment":
        this.getEquipment(value);
        break;
      default:
        break;
    }
  }

  /**
   * @param key field name
   * @param value field value
   * @param operator empty string or _AND_
   */
  assignSearch(key, value, operator): string {
    if (key === "customerName" && value) {
      this.downloadParams.customerName = this.dataListCustomer.find(
        item => item.id == value
      )?.firstName as string;
      return operator + `customerId~${value}`;
    } else if (key === "icNumber" && value) {
      this.downloadParams.icNumber = value;
      return operator + `icNumber~${value}`;
    } else if (key === "equipment" && value) {
      this.downloadParams.equipment = value;
      return operator + `equipment~${value}`;
    } else if (key === "tracking" && value) {
      this.downloadParams.tracking = value;
      return operator + `tracking~${value}`;
    } else if (key === "icDateFrom" && value) {
      this.downloadParams.icDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `icDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}`
      );
    } else if (key === "icDateTo" && value) {
      this.downloadParams.icDateTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `icDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`
      );
    } else if (key === "unitCode" && value) {
      this.downloadParams.unitCode = value;
      return operator + `unitCode~*${value}*`;
    } else return "";
  }

  /**
   * @param res form values;
   */
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  async findData(pagination: boolean): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "icNumber:asc",
        };
        for (const key in this.downloadParams) {
          if (key != "companyName") {
            this.downloadParams[key] = "ALL";
          }
        }
        if (this.dynamicSearch(res)) {
          params.search = this.dynamicSearch(res);
        }
        this.downloadParams.search = params.search as string;
        this.getData(params);
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }
  getData(params) {
    this.loading.find = true;
    salesOrderServices
      .listTrackingIc(params)
      .then(res => {
        this.dataSource = res.data;
        this.totalElements = res.totalElements;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }
}
